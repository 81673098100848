import type { RouteRecordRaw } from 'vue-router';
import { videoCaption } from '@/modules/videoCaption';
import languageList from '@/store/modules/languageList';
import store from '@/store';
import { captionProfileList } from '@/modules/captionProfile';

export function createVideoCaptionRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'subtitles',
      component: () => import(/* webpackChunkName: "VideoSubtitles" */ '../pages/VideoSubtitles.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('videoCaption')) {
          store.registerModule('videoCaption', videoCaption);
        }
        if (!store.hasModule('languageList')) {
          store.registerModule('languageList', languageList);
        }
        if (!store.hasModule('captionProfileList')) {
          store.registerModule('captionProfileList', captionProfileList);
        }
        next();
      },
    },
  ];
}
