<template>
  <Modal
    size="small"
    :title="t('video.subtitle.modal.status.title')"
    :primaryButtonText="t('video.subtitle.modal.status.change')"
    primaryButtonStyle="primary"
    :onClickPrimaryButton="onSave"
    :onClickSecondaryButton="onCloseModal"
    :isSaving="isSaving"
    @modalClose="onCloseModal"
  >
    <template v-slot:body>
      <div>
        <div class="radio-heading">
          {{ t('video.subtitle.status.choose') }}
          <b>'{{ caption.customName }}'</b>
        </div>
        <RadioButtonGroup :modelValue="selectedOption" :options="options" :onChange="onChange" />
      </div>
    </template>
    <template v-slot:footer>
      <div class="error-message" v-if="error">
        {{ _.get(error, 'response.data.message') }}
      </div>
    </template>
  </Modal>
</template>

<script setup>
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';
import { updateCaptionDraftStatus } from '@/modules/videoCaption/services';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import { useStore } from '../../../store/hooks';

const { t } = useI18n();
const store = useStore();

// eslint-disable-next-line no-undef
const props = defineProps({
  captionIndex: {
    type: Number,
  },
  onCloseModal: {
    type: Function,
  },
});

const caption = computed(
  () => store.state.videoCaption?.captionList?.length
    && store.state.videoCaption?.captionList[props.captionIndex],
);
const publishStatus = computed(() => caption.value?.publishStatus);

const options = ref([
  {
    label: t('video.subtitle.status.draft'),
    value: 'draft',
  },
  {
    label: t('video.subtitle.status.publish'),
    value: 'published',
  },
]);

const selectedOption = ref(publishStatus.value);
const isSaving = ref(false);
const onChange = (value) => {
  selectedOption.value = value;
};

const error = ref(null);

const onSave = async (event) => {
  event.stopPropagation();
  isSaving.value = true;
  try {
    if (publishStatus.value !== selectedOption.value) {
      const response = await updateCaptionDraftStatus(caption.value.key, selectedOption.value);
      if (response.status === 200) {
        const updatedStatus = response.data.publishStatus;
        store.dispatch(
          'updateCaptionByIndex',
          {
            index: props.captionIndex,
            payload: { publishStatus: updatedStatus },
          },
        );
        props.onCloseModal();
      }
    }
    isSaving.value = false;
  } catch (err) {
    error.value = err;
    isSaving.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.radio-heading {
  margin-bottom: 8px;
}

.error-message {
  color: $danger;
}
</style>
