<template>
  <Row
    :key="`${caption.languageCode}${caption.customName}${index}`"
    @click="isReady && !readonly ? $emit('onOpenEditSidebar'): () => {}"
    verticalAlign="center"
    :class="{
      badwordHighlight: hasBadWord,
      disabled: isProcessing,
    }"
  >
    <Column minWidth="140px">
      <TextCell :primaryText="caption.customName" :sub-text="captionType" />
    </Column>
    <Column width="60px">
      <TextCell :text="caption.languageCode" />
    </Column>
    <Column width="150px">
      <div
        class="status"
        @click="(event) => !readonly ? $emit('onOpenStatusModal',event): null"
        v-if="isReady"
      >
        <div class="status-text">
          <i class="fas fa-file" v-if="caption?.publishStatus === 'draft'"></i>
          <i class="fas fa-globe-americas" v-else></i>
          {{
            caption?.publishStatus === 'draft'
              ? $t('video.subtitle.status.draft')
              : $t('video.subtitle.status.published')
          }}
        </div>
        <i class="fas fa-pen"></i>
      </div>
      <div v-else-if="isProcessing">{{$t('video.subtitle.status.processing')}}...</div>
      <div v-else-if="isFailed" class="error">{{$t('video.subtitle.status.processingFailed')}}</div>
    </Column>
    <Column width="80px">
      <TextCell :text="caption.updatedAt && formatDateTime(caption.updatedAt)" />
    </Column>
    <Column width="85px" horizontal-align="right">
      <TextCell
        v-if="isReady && !hasBadWord"
        :text="badWordCount === 0 ? '0' : '-'"
        class="text-align-end"
      />
      <div
        v-else-if="isReady && hasBadWord"
        class="bad-word-count"
        @click="(event) => $emit('onOpenBadwordListModal', event)"
        :title="$t('video.subtitle.table.badWords.altText')"
      >
        <i class="fas fa-exclamation-triangle warning-icon"></i>
        <b>{{ badWordCount }}</b>
      </div>
    </Column>
    <Column width="100px" isActionColumn>
      <Button
        @click.stop="$emit('downloadFile')"
        buttonStyle="text-secondary"
        isSquare
        :disabled="!isReady"
        v-if="isReady"
      >
        <i class="fas fa-download"></i>
      </Button>
      <Dropdown
        v-if="isReady && !readonly"
        icon-class-name="fas fa-ellipsis-v"
        @click="onClickDropdown">
        <DropdownItem :onClick="() => $emit('onOpenEditSidebar', index)">
          <div class="icon-box">
            <i class="fas fa-pen"></i>
          </div>
          {{$t('common:button.edit')}}
        </DropdownItem>
        <DropdownItem v-if="isReady" :onClick="() => $emit('onOpenTranslateCaptionModal')">
          <div class="icon-box">
            <i class="fas fa-language"></i>
          </div>
          {{$t('video.subtitle.translate')}}
        </DropdownItem>
        <DropdownItem :onClick="() => $emit('onOpenDeleteCaptionModal')">
          <div class="icon-box">
            <i class="fas fa-trash-alt"></i>
          </div>
          {{$t('common:button.delete')}}
        </DropdownItem>
      </Dropdown>
      <Button
        v-if="isFailed"
        @click="$emit('onOpenDeleteCaptionModal')"
        buttonStyle="text-secondary"
        isSquare
      >
        <i class="fas fa-trash-alt"></i>
      </Button>
    </Column>
  </Row>
</template>

<script setup>
import {
  computed,
} from 'vue';
import { useStore } from '@/store';
import { formatDateTime } from '@/modules/shared/utils/dateFormatter';
import { serviceNameDict } from '@/modules/captionProfile/utils';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';

const store = useStore();

// eslint-disable-next-line no-undef
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

// eslint-disable-next-line no-undef
defineEmits([
  'onOpenEditSidebar',
  'onOpenBadwordListModal',
  'onOpenTranslateCaptionModal',
  'onOpenDeleteCaptionModal',
  'onOpenStatusModal',
  'downloadFile',
]);

const caption = computed(() => store.state.videoCaption?.captionList[props.index]);
const processingStatus = computed(() => caption.value?.processingStatus);
// ready or has no processingStatus field at all (older versions)
const isReady = computed(() => processingStatus.value === 'ready' || !processingStatus.value);
const isProcessing = computed(() => processingStatus.value === 'processing');
const isFailed = computed(() => processingStatus.value === 'error');

const captionType = computed(() => {
  if (caption.value.captionProcessingType) {
    const { type, service } = caption.value.captionProcessingType;

    if (type === 'transcribe' || type === 'translate') {
      return serviceNameDict[type][service];
    }
  }
  return 'Manual Upload';
});

const badWordCount = computed(() => {
  if (caption.value?.captionProofreading) {
    return caption.value.captionProofreading.flaggedData?.length;
  }
  // in case some captions have no proofreading field
  return false;
});
const hasBadWord = computed(() => badWordCount.value > 0);

const onClickDropdown = (event) => {
  event.stopPropagation();
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
.bad-word-header {
  font-size: $font-level-8;
  font-weight: $font-weight-bold;
  /* padding: $spacing-8 0; */
  text-transform: capitalize;
  background: $grey-100;
  text-align: right;
  width: 100%;

  :deep(.fas) {
    margin-left: $spacing-4;
    color: $grey-400;
  }
}

:deep(.dropdown-item) {
  color: $grey-800;
  margin-right: 0px;
}

.icon-box {
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: inline-block;
  text-align: right;
}
:deep(.btn-add-subtitle) {
  margin: auto;
  margin-top: $spacing-base * 1.5;
}
// :deep(.btn-clear-search) {
//   margin: auto;
//   margin-top: $spacing-base * 1.5;
// }
.date-text {
  white-space: nowrap;
}

.badwordHighlight {
  background-color: #fef9f1;
}

.processing {
  opacity: 0.3;
  cursor: not-allowed;
}

.status {
  padding: 8px 12px;
  border: 1px solid #ebedef;
  border-radius: 3px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.status-text {
  display: flex;
  gap: 8px;
  align-items: center;
  text-transform: capitalize;
}

.error {
  color: $danger;
}

.bad-word-count {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid $warning;
}

.warning-icon {
  color: $warning;
  font-size: $font-level-8;
}
</style>
