<template>
  <SlideOut
    :title="t('video.subtitle.edit.title')"
    :primaryButtonText="t('common:button.save')"
    :onClickPrimaryButton="onSubmit"
    :isSaving="savingCaptionState.status === 'saving'"
    :secondaryButtonText="t('common:button.cancel')"
    :onClickSecondaryButton="onClose"
    @slideOutClose="() => onClose()"
  >
    <div class="content">
      <FormInput
        :title="t('video.subtitle.displayName.title')"
        :subtitle="t('video.subtitle.displayName.subtitle')"
        :modelValue="name || ''"
        @update:modelValue="(value) => (name = value)"
      />
      <Field :title="t('video.subtitle.language.title')">
        <LanguageSelector
          v-if="isManual"
          @selected="(value) => (selectedLanguage = value.code6392T)"
          :placeholder="t('video.subtitle.language.select')"
          :languageCode="selectedLanguage || languageCode"
        />
        <Input v-else :placeholder="languageDisplayName" :disabled="true" :modelValue="caption?.languageCode || ''" />
      </Field>
      <div class="file-field">
        <Field
          :title="t('glossary:file')"
          :helpText="!isManual ? `${t('video.subtitle.edit.file.helpText')}` : ''"
        >
          <FileButton
            :enableReupload="isManual"
            :fileName="selectedFile ? selectedFile.name
              : (caption?.originalFilename ? caption?.originalFilename : caption?.customName)"
            @update:file="onUpdateFile"
            @downloadFile="downloadFile"
            :accept="['.srt', '.vtt']"
          />
        </Field>
        <div v-if="downloadFileError" class="error-message">File Download Failed.</div>
      </div>
      <Field :title="t('video.subtitle.badWords.title')">
        <div v-if="badWords && badWords.length === 0" class="info-text">
          {{ t('video.subtitle.edit.badWord.notFound') }}
        </div>
        <div v-else-if="badWords" class="warning">
          <i class="fas fa-exclamation-triangle warning-icon"></i>
          <b
          >{{ t('video.subtitle.edit.badWord.found') }} {{ badWords.length }}
            {{ t('video.subtitle.edit.badWord.sentences') }}</b
          >
        </div>
        <div v-else>{{ t('video.subtitle.edit.badWord.notAvailable') }}</div>
      </Field>
      <FormSelectOption
        :title="t('video.subtitle.status.title')"
        :options="statusOptions"
        :modelValue="selectedStatus"
        label="name"
        selectLabel=" "
        :searchable="false"
        @update:modelValue="onUpdateStatus"
        class="select-status"
        :placeholder="t('video.subtitle.status.select')"
      />
      <div class="divider"></div>
      <Field :title="t('common:updatedBy')" v-if="updater && updatedAt">
        <UserInfoItem :data="updater" :dateTime="updatedAt" />
      </Field>
    </div>
    <template v-slot:footer>
      <div class="save-status">
        <div v-if="savingCaptionState.status === 'success'" class="success">
          <i class="fas fa-check"></i> {{ t('common:button.saved') }}
        </div>
        <div v-if="savingCaptionState.status === 'error'" class="failed">
          <i class="fas fa-exclamation-triangle"></i> {{ savingCaptionState.error?.message }}
        </div>
      </div>
    </template>
  </SlideOut>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import FormInput from '@/modules/shared/components/molecules/formInput/FormInput.vue';
import FileButton from '@/modules/shared/components/molecules/fileButton/FileButton.vue';
import FormSelectOption from '@/modules/shared/components/molecules/formSelectOption/FormSelectOption.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import SlideOut from '@/modules/shared/components/organisms/slideOut/SlideOut.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import UserInfoItem from '@/modules/shared/components/atoms/userInfoItem/UserInfoItem.vue';
import LanguageSelector from '../../molecules/languageSelector/LanguageSelector.vue';
import { useStore } from '../../../store/hooks';
import {
  updateCaptionDraftName,
  updateCaptionDraftLanguage,
  updateCaptionDraftStatus,
  downloadCaptionFile,
  updateCaptionDraftFile,
} from '../../../services';

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
    default: () => {},
  },
  captionIndex: {
    type: Number,
    required: true,
  },
});

const caption = computed(() => {
  const { captionList = [] } = store.state.videoCaption;
  if (captionList?.length && props.captionIndex >= 0) {
    return captionList[props.captionIndex];
  }
  return null;
});

const key = computed(() => caption.value?.key);
const customName = computed(() => caption.value?.customName);
const publishStatus = computed(() => caption.value?.publishStatus);
const languageCode = computed(() => caption.value?.languageCode);
const badWords = computed(() => caption.value?.captionProofreading?.flaggedData);
const updater = computed(() => caption.value?.updater);
const updatedAt = computed(() => caption.value?.updatedAt);
const isManual = computed(() => caption.value?.captionProcessingType?.type === 'manual');

const languageList = computed(() => store.state.languageList.languageList);
const languageDisplayName = computed(
  () => languageList.value.find((lang) => lang.code6392T === languageCode.value)?.displayName,
);

const name = ref(customName.value);
const selectedLanguage = ref('');
const selectedFile = ref<File | null>(null);

const statusOptions = [
  { name: t('video.subtitle.status.draft'), status: 'draft' },
  { name: t('video.subtitle.status.publish'), status: 'published' },
];
const selectedStatus = ref(statusOptions.find((option) => option.status === publishStatus.value));

const onUpdateStatus = (value: { name: string, status: string }) => {
  selectedStatus.value = value;
};
const onUpdateFile = (file: File) => {
  selectedFile.value = file;
};

const downloadFileError = ref();
const downloadFile = async () => {
  const fileName = caption.value?.originalFilename || `${caption.value?.customName}.vtt`;
  try {
    const response = await downloadCaptionFile(key?.value);
    const captionFile = new File([response.data], `${fileName}`, {
      type: 'text/vtt',
    });
    const fileURL = URL.createObjectURL(captionFile);

    // create dummy  <a></a> for file download
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = `${fileName}`;

    a.click();
    URL.revokeObjectURL(fileURL);
  } catch (err) {
    downloadFileError.value = formatErrorObject(err, 'caption');
  }
};

const savingCaptionState = ref(setStateSavingStatusByType()); // saving || success || failed
const onSubmit = async () => {
  const allRequests = [];
  const updatedFields = [];

  try {
    savingCaptionState.value = setStateSavingStatusByType({ type: 'saving' });
    // call API if the value is changed from original in props
    if (name.value && name.value !== caption.value?.customName) {
      allRequests.push(updateCaptionDraftName(key.value, name.value));
      updatedFields.push({ customName: name.value });
    }
    if (selectedLanguage.value && selectedLanguage.value !== caption.value?.languageCode) {
      allRequests.push(updateCaptionDraftLanguage(key.value, selectedLanguage.value));
      updatedFields.push({ languageCode: selectedLanguage.value });
    }
    if (selectedStatus.value && selectedStatus.value.status !== publishStatus.value) {
      allRequests.push(updateCaptionDraftStatus(key.value, selectedStatus.value.status));
      updatedFields.push({ publishStatus: selectedStatus.value.status });
    }
    if (selectedFile.value) {
      allRequests.push(
        updateCaptionDraftFile(
          key.value,
          selectedFile.value,
        ),
      );
      updatedFields.push({ originalFilename: selectedFile.value?.name });
    }

    const responses = await Promise.all(allRequests);
    if (responses) {
      // update with latest response
      // cannot use responses from Promise.all as they're concurrent
      const updated = Object.assign({}, ...updatedFields);
      store.dispatch('updateCaptionByIndex', { index: props.captionIndex, payload: updated });
    }
    savingCaptionState.value = setStateSavingStatusByType({ type: 'success' });
  } catch (err) {
    savingCaptionState.value = setStateSavingStatusByType({
      type: 'error',
      error: formatErrorObject(err, 'Caption'),
    });
  }
};

watch(
  () => caption.value,
  () => {
    // clear old input ref when caption changes
    name.value = customName.value;
    selectedLanguage.value = languageCode.value || '';
    selectedStatus.value = statusOptions.find((option) => option.status === publishStatus.value);
    selectedFile.value = null;
  },
  { deep: true },
);

watch(
  () => key.value,
  () => {
    // clear saving state
    savingCaptionState.value = setStateSavingStatusByType();
  },
);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 450px;
  background-color: $bg-light;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  z-index: 10;

  .header {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 16px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px 24px 64px 24px;
    overflow: auto;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.info-text {
  font-size: $font-level-6;
  color: $grey-800;
}

.warning-icon {
  color: $warning;
}

.warning {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $grey-800;
  font-weight: $font-weight-bold;
  font-size: $font-level-6;
}

.file-field{
  display: flex;
  flex-direction: column;
  gap:8px;

  .error-message{
    color: $danger;
  }
}

.select-status {
  width: 200px;
}

:deep(.multiselect__content-wrapper) {
  width: 200px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $grey-200;
}

.save-status {
  min-width: 8px;
  .failed {
    color: $danger;
  }
  .success {
    color: $success;
  }
}

:deep(.form-label) {
  text-transform: capitalize;
}
</style>
