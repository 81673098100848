<template>
  <Modal
    :size="size"
    :title="title || $t('common:button.confirmRemove')"
    :primaryButtonText="buttonTitle || $t('common:button.confirm')"
    :primaryButtonStyle="buttonStyle"
    :onClickPrimaryButton="onConfirm"
    :secondaryButtonText="enableCancelButton && $t('common:button.cancel')"
    secondaryButtonStyle="text-secondary"
    :isSaving="isSaving"
    @modalClose="onClose">
    <template v-slot:body>
      <slot name="body" />
    </template>
    <template v-slot:footer>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    enableCancelButton: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: String,
      default: 'danger',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
</style>
