<template>
  <Modal
    :title="t('video.subtitle.modal.delete.title')"
    :primaryButtonText="t('common:button.delete')"
    primaryButtonStyle="danger"
    :onClickPrimaryButton="onDelete"
    :onClickSecondaryButton="onClose"
    :isSaving="deleteVideoCaptionState.status === 'saving'"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <div> {{t('video.subtitle.modal.delete.text')}} <b>"{{customName}}"</b> ?</div>
      <div>{{t('video.subtitle.modal.delete.helpText')}}</div>
    </template>
    <template v-slot:footer v-if="deleteVideoCaptionState.status === 'error'">
      <div class="danger">
        <i class="fas fa-xmark" /> {{deleteVideoCaptionState.error?.message}}
      </div>
    </template>
  </Modal>

</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { deleteCaptionDraftByKey } from '@/modules/videoCaption/services';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import { useStore } from '../../../store/hooks';

const route = useRoute();
const store = useStore();
const { t } = useI18n();

const filter = computed(() => route.query);
const deleteVideoCaptionState = ref(setStateSavingStatusByType());

const props = defineProps({
  onClose: {
    type: Function,
    default: () => {},
  },
  caption: {
    type: Object,
  },
});

const customName = computed(() => props.caption.customName);
const key = computed(() => props.caption.key);

const onDelete = async () => {
  try {
    // visibility field exists only on published caption, both active & private
    deleteVideoCaptionState.value = setStateSavingStatusByType({ type: 'saving' });
    const response = await deleteCaptionDraftByKey(key.value);
    deleteVideoCaptionState.value = setStateSavingStatusByType({ type: 'success' });
    if (response) {
      store.dispatch('reloadCaptionList', { filter: filter.value });
      props.onClose();
    }
  } catch (error) {
    deleteVideoCaptionState.value = setStateSavingStatusByType({ type: 'error', error: formatErrorObject(error, 'Caption') });
  }
};

</script>
